import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, Divider, Grid, Select, MenuItem, Alert, SelectChangeEvent } from '@mui/material';
import axios from 'axios';
import { DEFAULTS } from '../../../../Constants/Constants';
import Heading from '../../Common/Header/Heading';
interface AgentModalProps {
  open: boolean;
  onClose: () => void;
  row: any;
}

const Agent: React.FC<AgentModalProps> = ({ open, onClose, row }) => {
  const [commission, setCommission] = useState('');
  const token = localStorage.getItem('admin-token');
  const [status, setStatus] = useState('');
  const [error, setError] = useState(false); // State to track commission validation
  const [statusError, setStatusError] = useState(false); // State to track status selection
  const [submissionSuccess, setSubmissionSuccess] = useState(false); // State to track success response
  const [apiError, setApiError] = useState(false); // State to track API error

  const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  // Handle commission change and validation
  const handleCommissionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Only allow numbers and decimals
    if (/^\d*\.?\d*$/.test(value)) {
      setCommission(value);
      setError(false); // Reset error if input is valid
    } else {
      setError(true); // Set error if invalid input
    }
  };

  // Handle status change with SelectChangeEvent type
  const handleStatusChange = (e: SelectChangeEvent<string>) => {
    setStatus(e.target.value);
    setStatusError(false); // Reset status error when a valid value is selected
  };

  // Handle validation and submission on button click
  const handleSubmit = (action: string) => {
    if (!commission || isNaN(Number(commission))) {
      setError(true); // Show error if commission is empty or not a valid number
      return; // Prevent submission if invalid commission
    }
    if (!status) {
      setStatusError(true); // Show error if no status is selected
      return; // Prevent submission if no status
    }

    const dataToSubmit = {
      ...row,
      commission: commission,
      status: status, // Include the selected status
    };

    // Make the API request using Axios
    axios
  .post(DEFAULTS.API_URL + 'user', dataToSubmit, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then((response) => {
    console.log('Submission successful:', response.data);
    setSubmissionSuccess(true); // Set success state to true
    setApiError(false); // Reset API error state on success
    setTimeout(onClose, 2000); // Close the modal after 2 seconds
  })
  .catch((error) => {
    console.error('There was an error submitting the data:', error);
    setApiError(true); // Set API error state to true
  });

}
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        {submissionSuccess ? (
          // Show success message and close button after successful submission
          <>
            <Typography variant="h6" component="h2">
              {status === 'APPROVED' ? 'Agent successfully approved!' : 'Agent successfully rejected!'}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button variant="contained" color="secondary" onClick={onClose}>
                Close
              </Button>
            </Box>
          </>
        ) : (
          // Display the form content while not yet submitted successfully
          <>
            <Typography variant="h6" component="h2">
              Agent Details
            </Typography>
            <Divider sx={{ my: 2 }} />
            {row && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1"><strong>ID</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{row.agentId}</Typography>
                </Grid>
        
                <Grid item xs={6}>
                  <Typography variant="subtitle1"><strong>First Name</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{row.firstname}</Typography>
                </Grid>
        
                <Grid item xs={6}>
                  <Typography variant="subtitle1"><strong>Last Name</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{row.lastname}</Typography>
                </Grid>
        
                <Grid item xs={6}>
                  <Typography variant="subtitle1"><strong>Email</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{row.email}</Typography>
                </Grid>
        
                <Grid item xs={6}>
                  <Typography variant="subtitle1"><strong>Mobile</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{row.mobile}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1"><strong>State</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{row?.address}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle1"><strong>Country</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{row?.country}</Typography>
                </Grid>
        
                {(row?.status==='COMPLETED') && <><Grid item xs={6}>
                  <Typography variant="subtitle1"><strong>Status</strong></Typography>
                </Grid>
                <Grid item xs={6}><Typography variant="body1">{row.status}</Typography></Grid></>}
                {(row?.status!='COMPLETED') && <>
               
                <Grid item xs={6}>
                  <Typography variant="subtitle1"><strong>Commission</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={commission}
                    onChange={handleCommissionChange}
                    error={error}
                    helperText={error ? 'Please enter a valid number for commission' : ''}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1"><strong>Status</strong></Typography>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    value={status}
                    onChange={handleStatusChange}
                    fullWidth
                    error={statusError}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>Select Status</MenuItem>
                    <MenuItem value="APPROVED">APPROVED</MenuItem>
                    <MenuItem value="REJECTED">REJECTED</MenuItem>
                  </Select>
                  {statusError && (
                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                      Please select a status
                    </Typography>
                  )}
                </Grid></>}
              </Grid>
            )}
            <Divider sx={{ my: 2 }} />
    
            {/* Display Error Alert if commission or status is invalid */}
            {(error || statusError) && (
              <Alert severity="error" sx={{ mb: 2 }}>
                Please fill all the fields correctly before submitting.
              </Alert>
            )}

            {/* Display API error message if an error occurred during the Axios request */}
            {apiError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                Error occurred. Please try again.
              </Alert>
            )}
    
            <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
            {(row?.status!='COMPLETED') &&   <Button variant="contained" onClick={() => handleSubmit(status)} disabled={error || statusError}>
                {status === 'APPROVED' || status === 'REJECTED' ? status : 'Submit'}
              </Button> }
              <Button variant="contained" color="secondary" onClick={onClose}>
                Close
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default Agent;
