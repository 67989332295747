import React, {useState, useEffect} from 'react';
import { Container, Grid,Box, TextField,IconButton, Button, InputLabel, Select, MenuItem,AlertTitle, FormControl, Card,CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import Divider from '@mui/material/Divider';

import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'; // Customer Icon
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridFilterModel } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import PostAddIcon from '@mui/icons-material/PostAdd';
import PageviewIcon from '@mui/icons-material/Pageview';
import Tooltip from '@mui/material/Tooltip';
import { Chip } from '@mui/material';
import axios from 'axios';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import Heading from '../../Common/Header/Heading';
import DescriptionIcon from '@mui/icons-material/Description';
import { DEFAULTS } from '../../../../Constants/Constants'
import {convertFirestoreTimestampToDate} from '../../../../util/DisplayUtils'
type MyState = Array<any> | Record<string, any>;


const pre = (ship_type: any) => {
  switch (ship_type) {
    case 'air':
      return "A1";
    case 'ocean':
    return "S1";
      case 'local-courier':
        return "L1";
    default:
      return "T1";
  }
};
function Quote() {
    const [shipQuotes, setShipQuotes] = useState([]);
    const [shipErrors, setShipError] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [dataFetched, setDataFetched] = useState(true)
    const token = localStorage.getItem('admin-token');
    const navigate = useNavigate();
    const columns = [
      {
        field: "quoteId",
        headerName: "OrderId",
        width: 100,
        renderCell: (params: any) => (

       <a href={`/portal/quote/${params.row.quoteId}`}>{pre(params.row.quoteType) + params.row.quoteId}</a>
        )
      },
      {
        field: "quoteDate",
        headerName: "Date",
        width: 200,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          convertFirestoreTimestampToDate(params.row.quoteDate)
        )
      },
      {
        field: "quoteName",
        headerName: "Name",
        width: 200,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          params.row.quoteName.toUpperCase()
        )
      },
      {
        field: "quoteEmail",
        headerName: "Email",
        width: 200,
        sortable: true,
        filter:false
      },
      {
        field: "quoteMobile",
        headerName: "Mobile",
        width: 100,
        sortable: true,
        filter:false
      },
      {
        field: "quoteType",
        headerName: "Type",
        width: 100,
        sortable: true,
        filter:false,
        renderCell: (params: any) => (
          params.row.quoteType.toUpperCase()
        )
      },
      {
        field: "quoteAssigned",
        headerName: "Assigned To",
        width: 200,
        sortable: true,
        filter:false
      },
      {
        field: "quoteStatus",
        headerName: "Status",
        width: 100,
        renderCell: (params: any) => (
          <Chip
    label={params.row.quoteStatus}
    size="small"
    sx={{
      borderRadius: '50px',
      backgroundColor: (() => {
        switch (params.row.quoteStatus) {
          case 'COMPLETED':
            return 'green';
          case 'NEW':
            return 'orange';
            case 'IN PROGRESS':
            return 'lightgreen';
          case 'REJECTED':
            return 'lightcoral';
          default:
            return 'lightgray'; // Default background color for other statuses
        }
      })(),
      
      fontWeight: 'bold',
    }}
  />
  
          
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        renderCell: (params: any) => (
          <Tooltip title="View Quote">
    <IconButton onClick={() => navigate(`/portal/quote/${params.row.quoteId}`)}>
      <PageviewIcon  />
    </IconButton>
  </Tooltip>
          
        ),
      },
    ];
  



      const getQuotes = async () => {
        setLoading(true)
        await axios.get(DEFAULTS.API_URL+'quotes',{headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        
           .then(
             response => {
              const rowsWithIds = response.data.map((row: any, index: any) => ({
                ...row,
                id: index + 1, // You can use any unique identifier here
              }));
              setShipQuotes(rowsWithIds.sort((a: any, b: any) => b.quoteId - a.quoteId));
              setLoading(false);
                            
             }
              // response.data.rate_response.rates
             
           )
       }
       
       useEffect(() => {
         // Update the document title using the browser API
         //expensesListResp();
         getQuotes();
       },[]);

       const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
          { field: 'quoteName', operator: 'contains', value: '' },
          { field: 'quoteEmail', operator: 'contains', value: '' },
          { field: 'quoteMobile', operator: 'contains', value: '' },
          { field: 'quoteAssigned', operator: 'contains', value: '' },
           // Correct property
        ],
      });
    
      const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilterModel({
          items: [
            { field: 'quoteName', operator: 'contains', value: e.target.value },
            { field: 'quoteEmail', operator: 'contains', value: e.target.value },
            { field: 'quoteMobile', operator: 'contains', value: e.target.value },
            { field: 'quoteAssigned', operator: 'contains', value: e.target.value }, // Corrected property name
          ],
        });
      };

    return (
    
      <>
          <Heading icon={<DescriptionIcon color="secondary" />} text="Quotes" />
            <Card variant="outlined">
                <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
        <TextField
          label="Filter by Name, Email, Company Name"
          variant="outlined"
          fullWidth
          onChange={handleFilterChange}
        />
        <IconButton 
            sx={{ color: 'red' }} // Red icon color
            aria-label="quotes" 
            onClick={() => navigate('/portal/quote/create')} // Navigate to /quote
          >
            <PostAddIcon />
          </IconButton>
      </Box>
                <DataGrid
        rows={shipQuotes}
        columns={columns}
        autoHeight
        getRowId={(row: any) => row.quoteId}
        loading={loading}       
        style={{marginBottom:10}}
        filterModel={filterModel} // Apply the filter model
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
      />

</CardContent>
</Card>
      

      </>
    );
  }


  
  export default Quote;