import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';

interface IconWithTextProps {
  icon: React.ReactNode; // Allow any valid React element, such as MUI icons
  text: string;          // Text to display next to the icon
}

const Heading: React.FC<IconWithTextProps> = ({ icon, text }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <IconButton>
            {icon}
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h5">{text}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Heading;
