import React, {useState, useEffect} from 'react';

import {TextField, Container,Card, CardContent,Divider, Typography, Button, Modal, } from '@mui/material';
import { BrowserRouter, Routes, Route,  Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import 'react-data-grid/lib/styles.css';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid'; // Grid version 1
import PageviewIcon from '@mui/icons-material/Pageview';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import axios from 'axios';
import { Chip, Box } from '@mui/material';
import Heading from '../../Common/Header/Heading';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Autocomplete from '@mui/material/Autocomplete';
import Agent from './Agent'; // Import the modal component
import Paper from '@mui/material/Paper';
import { DEFAULTS } from '../../../../Constants/Constants'
const columns = [
  { key: 'shipmentId', name: 'Order Id' },
  { key: 'shipmentOrderDate', name: 'Date' },
  { key: 'ship_date', name: 'Shipment Date' },
  { key: 'estimated_delivery_date', name: 'Delivery Date' },
  { key: 'shipperName', name: 'Ship To' },
 { key: 'service_type', name: 'Method' },
 { key: 'shipmentGrandTotal', name: 'Amount(USD)' },
 { key: 'shipmentStatus', name: 'Status' },

];


function Agents() {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const token = localStorage.getItem('admin-token');
  const [dataFetched, setDataFetched] = useState(true)
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const handleOpen = (row: any) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  console.log('token', token)
  const clickShipment = () => {
    console.log("logout")
    navigate('/portal/shipments')
  };
  const getShipment = (shipmentId: any) => {
    console.log("logout",shipmentId)
    navigate(`/portal/shipment/${shipmentId}`)
  };
  const getRecords = async () => {
    try {
      const response = await axios.get(DEFAULTS.API_URL + 'agents', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      let records = response.data;
      records = records.sort((a: any, b: any) => {
        let da: any = new Date(b.agentId);
        let db: any = new Date(a.agentId);
        return da - db;
      });
  
  
      setRecords(records);
      setDataFetched(false);
    } catch (error) {
      // Handle the error here, e.g., display an error message or log it.
      console.error('Error fetching records:', error);
    }
  };
  
  useEffect(() => {
    getRecords();
  }, []);

  const columns = [
    {
      field: "email",
      headerName: "Email",
      width: 200,
      sortable: true,
      filter:false
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 200,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        params.row.firstname ? params.row.firstname.toUpperCase() : ''
      )
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 250,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        params.row.lastname ? params.row.lastname.toUpperCase() : ''
      )
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 200,
      sortable: true,
      filter:false
    },
    {
      field: "address",
      headerName: "State",
      width: 100,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        params.row.address ? params.row.address.toUpperCase() : ''
      )
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
      sortable: true,
      filter:false,
      renderCell: (params: any) => (
        params.row.country ? params.row.country.toUpperCase() : ''
      )
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params: any) => (
        <Chip
  label={params.row.status}
  size="small"
  sx={{
    borderRadius: '50px',
    backgroundColor: (() => {
      switch (params.row.status) {
        case 'COMPLETED':
          return 'lightgreen';
        case 'NEW':
          return 'lightblue';
        case 'REJECTED':
          return 'lightcoral';
        default:
          return 'lightgray'; // Default background color for other statuses
      }
    })(),
    
    fontWeight: 'bold',
  }}
/>

        
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params: any) => (
        <Tooltip title="View Agent/User Details">
  <IconButton onClick={() => handleOpen(params.row)}>
    <PageviewIcon  />
  </IconButton>
</Tooltip>
      ),
    },
  ];

  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }

    return (
<>

<Heading icon={<PersonAddIcon color="secondary" />} text="Agents Registration" />
    <Card variant="outlined">
        <CardContent>

        {/* {records && (<DataGrid 
        columns={columns} 
        rows={records}
        />)} */}
       <DataGrid
        rows={records}
        columns={columns}
        autoHeight
        getRowId={(row: any) => row.agentId}
        loading={dataFetched}       
        style={{marginBottom:10}}
      />
          </CardContent>
          </Card>
          <Agent
        open={open}
        onClose={handleClose}
        row={selectedRow}
      />
</>
    );
  }

  

  export default Agents;