import React, {useState, useEffect} from 'react';

import {TextField, Container,Card, CardContent,Divider, Typography} from '@mui/material';
import { BrowserRouter, Routes, Route,  Outlet, useParams} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Link from '@mui/material/Link';
import 'react-data-grid/lib/styles.css';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid'; // Grid version 1
import LaunchIcon from '@mui/icons-material/Launch';
import moment from 'moment';
import axios from 'axios';
import Heading from '../../Common/Header/Heading';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import Autocomplete from '@mui/material/Autocomplete';

import Paper from '@mui/material/Paper';
import { DEFAULTS } from '../../../../Constants/Constants'


type customerType = {
  [key: string]: any;
}


const validationSchema = yup.object({
  firstname: yup
  .string()
  .min(3, 'First name should atleast contain 3 characters')
  .max(30, 'First name cannot exceed 30 characters')
  .required('Enter first name')
  .matches(/^[a-zA-Z\s]+$/,'Please enter only alphabets'),
  lastname: yup
  .string()
  .min(3, 'Last name should atleast contain 3 characters')
  .max(30, 'Last name cannot exceed 30 characters')
  .required('Enter last name')
  .matches(/^[a-zA-Z\s]+$/,'Please enter only alphabets'),
  mobile: yup
  .string()
  .min(3, 'Last name should atleast contain 10 characters')
  .max(30, 'Last name cannot exceed 15 characters')
  .required('Enter mobile number')
  .matches(/^[0-9+\s]+$/,'Please enter only numbers'),
  company: yup
  .string()
  .min(3, 'Company name should atleast contain 3 characters')
  .max(30, 'Company name cannot exceed 30 characters')
  .matches(/^[a-zA-Z0-9\s]+$/,'Please enter only alphabets and numbers'),
  username: yup
  .string()
  .min(9, 'Enter a valid email')
  .max(50, 'Enter a valid email')
  .required('Enter an email')
  .matches(/^[a-zA-Z0-9._@]+$/, 'Enter a valid email')
});

function generateRandomString(length: any) {
  return Math.random().toString(36).substr(2, length);
}


function CustomerRecord() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [record, setRecords] =useState<customerType>();
  const token = localStorage.getItem('admin-token');
  const [dataFetched, setDataFetched] = useState(true)
  const[formError, setFormError] = useState('');
  console.log('token', token)
  const randomString = generateRandomString(10);


  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      username: '',
      password: randomString,
      company:'',
      mobile:'',
      wallet:0,
      acceptterms:false,
      acceptrefundpolicy:false,
      acceptprivacypolicy: false

    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
     console.log("values", values)
     const expensesListResp = async () => {

      await axios.post(DEFAULTS.API_URL + 'register', values)
      .then(
        response => {
          console.log("response", response.data)
          if (response.data.status =='success')
          {
            navigate('/portal/customers');
          }
          else {

            setFormError(response.data.msg)
          }
        }
      )
        
    }
    expensesListResp()
    },
  });

  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }

    return (
<>

<Heading icon={<GroupIcon color="secondary" />} text="Create Customer" />
    <Card variant="outlined">
        <CardContent >
        
        <Grid container spacing={3}>

<Grid item xs={12} md={9} lg={9}>
<Box component="form" onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
        <Table size="small">
        <TableBody>
        <TableRow><TableCell>First Name: </TableCell><TableCell>
        <TextField
                  name="firstname"
                  
                  id="firstname"
 size="small"
                  autoFocus
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                  error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                  helperText={formik.touched.firstname && formik.errors.firstname}
                />
</TableCell></TableRow>
        <TableRow><TableCell>Last Name: </TableCell><TableCell>
        <TextField
                 
                  id="lastname"
 size="small"
                  name="lastname"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                  error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                  helperText={formik.touched.lastname && formik.errors.lastname}
                />
          </TableCell></TableRow>
        <TableRow><TableCell>Company:</TableCell><TableCell>
          
        <TextField
                  
                  id="company"
 size="small"
                  name="company"
                  value={formik.values.company}
                  onChange={formik.handleChange}
                  error={formik.touched.company && Boolean(formik.errors.company)}
                  helperText={formik.touched.company && formik.errors.company}
                />
          </TableCell></TableRow>
        <TableRow> <TableCell>Mobile: </TableCell><TableCell><TextField
               
                  id="mobile"
 size="small"
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={formik.handleChange}
                  error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                  helperText={formik.touched.mobile && formik.errors.mobile}
                /></TableCell></TableRow>
        <TableRow> <TableCell>Email: </TableCell><TableCell><TextField
                 
                  id="username"
 size="small"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={formik.touched.username && Boolean(formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                /></TableCell></TableRow>


       </TableBody>
          </Table>
          <Button
              type="submit"
              
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{backgroundColor:'#eb4034'}}
            >
              Create Customer
            </Button>
            </Box>
            </Grid>
            </Grid>
          

          </CardContent>
          </Card>
</>
    );
  }

  

  export default CustomerRecord;