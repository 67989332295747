import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, TextField, Container, Card, CardContent, Box, Grid, Typography, Alert } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { DEFAULTS } from '../../../../Constants/Constants';

const validationSchemaPassword = yup.object({
  password: yup
    .string()
    .min(5, 'Enter a valid password with at least 5 characters')
    .max(12, 'Enter a valid password')
    .required('Enter a password'),
  passwordNew: yup
    .string()
    .oneOf([yup.ref('password'), ''], 'Passwords must match')
    .required('Confirm your password'),
});

export default function Profile() {
  const navigate = useNavigate();
  const token = localStorage.getItem('admin-token');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [formSuccess, setFormSuccess] = useState(false);

  const formikPass = useFormik({
    initialValues: {
      password: '',
      passwordNew: ''
    },
    validationSchema: validationSchemaPassword,
    onSubmit: (values) => {
      axios
        .put(DEFAULTS.API_URL + "user/password", values, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((response) => {
          // Handle success response
          setFormSuccess(true);
          setPasswordError(null); // Clear any previous error
          formikPass.resetForm(); // Reset the form
        })
        .catch((error) => {
          // Handle error response
          setFormSuccess(false);
          setPasswordError(
            error.response?.data?.msg || 'An unexpected error occurred.'
          );
        });
    },
  });

  return (
    <Container>
      <Typography variant="h5">Change Password</Typography>
      <Box component="form" onSubmit={formikPass.handleSubmit} sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            {/* Show success message */}
            {formSuccess && (
              <Alert severity="success" sx={{ mb: 2 }}>
                Password updated successfully!
              </Alert>
            )}

            {/* Show error message */}
            {passwordError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {passwordError}
              </Alert>
            )}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="password"
                  label="New Password"
                  type="password"
                  id="password"
                  size="small"
                  autoComplete="current-password"
                  value={formikPass.values.password}
                  onChange={formikPass.handleChange}
                  error={formikPass.touched.password && Boolean(formikPass.errors.password)}
                  helperText={formikPass.touched.password && formikPass.errors.password}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  name="passwordNew"
                  label="Confirm Password"
                  type="password"
                  id="passwordNew"
                  size="small"
                  autoComplete="current-password"
                  value={formikPass.values.passwordNew}
                  onChange={formikPass.handleChange}
                  error={formikPass.touched.passwordNew && Boolean(formikPass.errors.passwordNew)}
                  helperText={formikPass.touched.passwordNew && formikPass.errors.passwordNew}
                />
              </Grid>
            </Grid>
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Change Password
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
