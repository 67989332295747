import React, {useState, useEffect, useMemo} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import Deposits from './Deposits';
import QuoteCard from './QuoteCard';
import axios from 'axios';
import { RootState, useAppDispatch } from '../../../../store'
import { useSelector } from 'react-redux'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { DEFAULTS } from '../../../../Constants/Constants'

type dataType = {
  [key: string]: any;
}
type adminType = {
  [key: string]: any;
}
function DashboardAgent() {
  const token = localStorage.getItem('admin-token');
  const [dataFetched, setDataFetched] = useState(true)
  const [records, setRecords] = useState<dataType | null>(null);
  console.log('token', token)
  const ADMINDETAILS: adminType | null = useMemo(() => {
    const adminDetailsString = localStorage.getItem('admin-details');
    return adminDetailsString ? JSON.parse(adminDetailsString) : null;
  }, []);

  const usertype = ADMINDETAILS?.type.toLowerCase()
  const getDashboard = async () => {
    // setLoading(true)
    const response = await fetch(DEFAULTS.API_URL+'dashboard',  {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    let records = await response.json();
    console.log("records",records)
        setRecords(records);
    }
  useEffect(() => {
    getDashboard();

  },[]);
 
    return (
<Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>

            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
              <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 150,
                  }}
                >
                  <React.Fragment>
      <Title> { usertype === 'agent'? 'My' :''} Commission</Title>
      <Typography component="p" variant="h4" style={{marginBottom:20}}>
       ${Number(records?.commission).toFixed(2)}
      </Typography>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography> */}
      <div>
     { usertype === 'admin' ? <Link color="primary" href="/portal/commission">
          View
        </Link> :<Link color="primary" href="/portal/my-commission">
          View
        </Link>}
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
              <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 150,
                  }}
                >
                  <React.Fragment>
      <Title>{ usertype === 'agent'? 'My' :''} Quotes</Title>
      <Typography component="p" variant="h4" style={{marginBottom:20}}>
      {Number(records?.quote)}
      </Typography>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography> */}
      <div>
        <Link color="primary" href="/portal/quotes">
          View
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
              <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 150,
                  }}
                >
                  <React.Fragment>
      <Title> { usertype === 'agent'? 'My' :''} Orders</Title>
      <Typography component="p" variant="h4" style={{marginBottom:20}}>
      {Number(records?.order)}
      </Typography>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography> */}
      <div>
        <Link color="primary" href="/portal/orders">
          View
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
              <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 150,
                  }}
                >
                  <React.Fragment>
      <Title> { usertype === 'agent'? 'My' :''} Customers</Title>
      <Typography component="p" variant="h4" style={{marginBottom:20}}>
      {Number(records?.userCount)}
      </Typography>
      {/* <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography> */}
      <div>
        <Link color="primary" href="/portal/customers">
          View
        </Link>
      </div>
    </React.Fragment>
                </Paper>
              </Grid>
              </Grid>
              </Grid>
      
              {/* Recent Orders */}
              <Grid item md={12} lg={12} >
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <QuoteCard />
                </Paper>
              </Grid>
            </Grid>
                           
    );
  }

  

  export default DashboardAgent;