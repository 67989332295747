import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PageviewIcon from '@mui/icons-material/Pageview';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Title from './Title';
import { Chip, Box } from '@mui/material';
import { DEFAULTS } from '../../../../Constants/Constants'
import axios from 'axios';
import {convertFirestoreTimestampToDate} from './../../../../util/DisplayUtils'

// Generate Order Data
function createData(
  id: number,
  date: string,
  name: string,
  shipTo: string,
  paymentMethod: string,
  amount: number,
) {
  return { id, date, name, shipTo, paymentMethod, amount };
}

const rows = [
  createData(
    0,
    '16 Mar, 2019',
    'Elvis Presley',
    'Tupelo, MS',
    'VISA ⠀•••• 3719',
    312.44,
  ),
  createData(
    1,
    '16 Mar, 2019',
    'Paul McCartney',
    'London, UK',
    'VISA ⠀•••• 2574',
    866.99,
  ),
  createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
  createData(
    3,
    '16 Mar, 2019',
    'Michael Jackson',
    'Gary, IN',
    'AMEX ⠀•••• 2000',
    654.39,
  ),
  createData(
    4,
    '15 Mar, 2019',
    'Bruce Springsteen',
    'Long Branch, NJ',
    'VISA ⠀•••• 5919',
    212.79,
  ),
];

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}



export default function QuoteCard() {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const token = localStorage.getItem('admin-token');
  console.log('token', token)
  const clickShipment = () => {
    console.log("logout")
    navigate('/portal/shipments')
  };
  const getShipment = (shipmentId: any) => {
    console.log("logout",shipmentId)
    navigate(`/portal/shipment/${shipmentId}`)
  };

  const handleOpen = (row: any) => {
    navigate(`/portal/shipment/${row['quoteId']}`);
  };
  const getRecords = async () => {
    try {
      const response = await axios.get(DEFAULTS.API_URL + 'quotes/new', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      let records = response.data;
      records = records.sort((a: any, b: any) => {
        let da: any = new Date(b.quoteId);
        let db: any = new Date(a.quoteId);
        return da - db;
      });
  
  
      setRecords(records);
      //setDataFetched(false);
    } catch (error) {
      // Handle the error here, e.g., display an error message or log it.
      console.error('Error fetching records:', error);
    }
  };
  
  useEffect(() => {
    getRecords();

  },[]);

  const getDate = (tmpStamp: any): string => {
    const dateObj = new Date(tmpStamp);
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
  
    const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
    console.log(dateString);
    return dateString;
  };
  
 const getName = (row: any): string =>
 {
 const shipTo =row.shipmentOrderRecord.shipmentOrder.shipments.ship_to
 return shipTo.name
 }
  return (
    <React.Fragment>
      <Title>Recent Quotes ({records.length})</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
          <TableCell>Quote Id</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Ship To</TableCell>

            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
           {records?.slice(0,10).map((row: any, idx: any) =>  (
            <TableRow key={row['_id']}>
              <TableCell>
              <Link color="primary" href={`/portal/quote/assign/${row['quoteId']}`} >{row['quoteId']}</Link></TableCell>
              <TableCell>{convertFirestoreTimestampToDate(row['quoteDate'])}</TableCell>
              <TableCell>{row['quoteName'].toUpperCase()}</TableCell>
              <TableCell>{row['quoteType'].toUpperCase()}</TableCell>
              <TableCell>{row['quoteId']}</TableCell>
              <TableCell>
  
<Chip
  label={row['quoteStatus'].toUpperCase()}
  size="small"
  sx={{
    borderRadius: '50px',
    backgroundColor: 'lightblue',
    
    fontWeight: 'bold',
  }}
/>
  </TableCell>
              <TableCell><Tooltip title="View Quote">
  <IconButton onClick={() => handleOpen(row)}>
    <PageviewIcon  />
  </IconButton>
</Tooltip></TableCell>
              

              
            </TableRow>
          ))} 
        </TableBody>
      </Table>
      {(records.length > 5) && (
      <Link color="primary" onClick={clickShipment} sx={{ mt: 3 }}>
        View more quotes
      </Link>
      )}
    </React.Fragment>
  );
}