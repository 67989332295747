import React , {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { logEvent } from "firebase/analytics";




export default function QuoteComplete() {

  const navigate = useNavigate();
 
  return (
    <div>
        
    <Container>
    <Typography variant="h5">Quote Created </Typography>
        <Card variant="outlined">
           <Typography component="h1" variant="h5">Quote has been created</Typography>

           <Typography variant="body1">Please return to the quotes page to view/edit created quotes</Typography>

              {/* <CopyRight sx={{ mt: 5 }} /> */}
          </Card>
          
        </Container>
        </div>

  );
}