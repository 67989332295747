import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, TextField, Button, InputLabel, Select, MenuItem, AlertTitle, FormControl, Card, CardContent, Link, Alert, Typography, Autocomplete } from '@mui/material';
import { BrowserRouter, Routes, Route,Outlet, useNavigate, useParams } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Modal from '@mui/material/Modal';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FlightIcon from '@mui/icons-material/Flight';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddIcon from '@mui/icons-material/Add';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AnchorIcon from '@mui/icons-material/Anchor';
import { SelectChangeEvent } from '@mui/material/Select';
import { Formik, Form, FieldArray, getIn, ErrorMessage, FormikConsumer } from 'formik';
import axios from 'axios';
import { useFormik } from 'formik';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import moment from 'moment';
import * as yup from 'yup';
import { DEFAULTS } from '../../../../Constants/Constants'
//import { getPackageName } from '../../util/DisplayUtils';
//import { firebaseApp, analytics } from "../../lib/firebase";
import { logEvent } from "firebase/analytics"
import Finder from "./Finder"
//import CargoItem from "../../portal/components/Common/CargoItem/CargoItem"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import Switch from '@mui/material/Switch';
import { Field, FieldProps } from 'formik';
import { AirOutlined } from '@mui/icons-material';
import { red } from '@mui/material/colors';
//import './Login.css';
import Heading from '../../Common/Header/Heading';
import DescriptionIcon from '@mui/icons-material/Description';
import {pre} from '../../../../util/DisplayUtils'
const defaultTheme = createTheme();
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        color: "yellow",
        backgroundColor: "red"
      }
    }
  }
});
const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "white",
    backgroundColor: '#eb4034',
    padding: 10,
    fontSize: 12
  }
});

type MyState = Array<any> | Record<string, any>;
interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}
interface sortedData {
}

interface MyType {
  service_code: string;
  service_type: string;
  carrier_delivery_days: number;
  error_messages: string[];
  shipping_amount: {
    amount: number;
    // Other properties related to shipping_amount
  };
  // Other properties
}


interface MyObject {
  weight: {
    value: string,
    unit: string
  },
  dimensions: {
    unit: string,
    length: string,
    width: string,
    height: string
  },
  insured_value: {
    currency: string,
    amount: number
  }
}
interface RateType {
  service_code: string;
  mark_up: string;
  // Other properties related to rates
}
const today = new Date();
// Set the time to midnight (00:00:00)
today.setHours(0, 0, 0, 0);
const validationSchema = yup.object({
  ship_type: yup.string().required('Ship type is required'),
  load_type: yup.string().test({
    name: 'required-for-ocean-or-truck',
    message: 'Load type is required',
    test: function (value, context) {
      const shipType = context.parent.ship_type; // Accessing ship_type from the parent context
      if (shipType === 'ocean' || shipType === 'truck') {
        // Check if load_type is provided for ocean or truck
        return typeof value === 'string' && value.trim() !== ''; // Return true if load_type is provided and not empty
      }
      return true; // Return true if ship_type is not ocean or truck
    }
  }),
  // ship_date: yup.date()
  // .required('Select a ship date')
  // .min(today, 'Ship date must be today or future'),
  from_where: yup.string().required('Select an origin'),
  to_where: yup.string().required('Select a destination'),
  ship_name: yup.string().required('Name is required')
  .matches(/^[a-zA-Z\s]+$/, 'Please enter alphabets and spaces'),
  ship_email: yup.string().required('Email is required')
  .matches(
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    'Please enter a valid email address.'
  ),
  ship_mobile: yup.string().required('Mobile is required')
  .matches(
    /^\+?\d+$/,
    'Please enter a valid mobile number.'
  ),
  requester_type: yup.string().required('Requester type is required'),
  currency: yup.string().required('Select a currency')
  .test('notEmptyOrZero', 'Select Currency', value => value !== '0'),
  inco_term: yup.string().required('Select an Inco Term')
      .test('notEmptyOrZero', 'Select Inco Term', value => value !== '0'),
  commodity: yup.string()
    .required('Select a commodity')
    .test('notEmptyOrZero', 'Select a commodity', value => value !== '0'),
  // full_load_container_type: yup.string()
  //   .required('Select Container Type')
  //   .test('notEmptyOrZero', 'Select Container Type', value => value !== '0')
  //   .test('load_type', 'Select Container Type', value => (value ==='ftl' || value ==='fcl')),

  // full_load_container_type: yup.string()
  //   .when('load_type', (load_type: any) => {
  //     if (load_type ==='ftl' || load_type ==='fcl') {
  //       console.log(" inside loop", load_type)
  //         return yup.string()
  //             .required('Select Container Type')
  //             .test('notEmptyOrZero', 'Select Container Type', value => value !== '0')
  //     } 
  //     return yup.string()
  // }),
 
  // full_load_container: yup.string().when('load_type', {
  //   is: ('ftl' || 'fcl'),
  //     then: (schema) => schema.required('Select Container Type'),
  //     otherwise: (schema) => schema.notRequired(),
  //   }),

  // full_load_container: yup.string()
  // .required('This field is required')
  // .test('notEmptyOrZero', 'Select Container Size', value => value !== '0')
  // .when('load_type', (load_type: any) => {
  //     if (load_type ==='ltl' || load_type ==='lcl') {
  //         return yup.string()
  //             .min(load_type, 'Select Container  Size')
  //             .typeError('Select Container Size')
  //     } 
  //     return yup.string()
  // }),
  // full_load_container: yup.string()
  //   .required('Select Container Size')
  //   .test('notEmptyOrZero', 'Select Container Size', value => value !== '0')
  //   .test('load_type', 'Select Container Type', value => (value ==='ftl' || value ==='fcl')),
  
  // full_load_container: yup.string()
  // .required("Select Container Size")
  // .test('notEmptyOrZero', 'Select Container Size', value => value !== '0'),
  full_load_container: yup.string()
  .when('load_type', ([load_type], sch) => {
    return ['fcl', 'ftl'].indexOf(load_type) > -1
      ? sch
        .required("Select Container Size")
        .test('notEmptyOrZero', 'Select Container Size', value => value !== '0')
      : sch.notRequired();
  }),
  full_load_container_type: yup.string()
  .when('load_type', ([load_type], sch) => {
    return ['fcl'].indexOf(load_type) > -1 
      ? sch
        .required("Select Container Type")
        .test('notEmptyOrZero', 'Select Container Type', value => value !== '0')
      : sch.notRequired();
  }),
  full_load_quantity: yup.number().min(1, 'Quantity cannot be zero').required('Enter quantity').nullable(),
  full_load_weight: yup.number().min(0, 'Quantity cannot be zero').nullable(),
  // full_load_commodity: yup.string().required('Select a Commodity')
  // .test('notEmptyOrZero', 'Select a Commodity', value => value !== '0'),
  //load_type: yup.string().required('Ship type is required'),
  items: yup.array().of(
    yup.object().shape({
    
     packaging: yup.string().required('Select a packaging').nullable(),
     quantity: yup.number().required('Weight is required').min(1, 'Quantity cannot be zero').nullable(),
      weight: yup.object().shape({
      value:yup.number().required('Weight is required').positive('Weight value must be positive'),
      }),
      dimensions: yup.object().shape({
      height: yup.number().required('Weight is required').min(1, 'Quantity cannot be zero').nullable(),
      width: yup.number().required('Weight is required').min(1, 'Quantity cannot be zero').nullable(),
      length: yup.number().required('Weight is required').min(1, 'Quantity cannot be zero').nullable()


      })
  })
  )
  })

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const positive = {
  color:'green'
}
const errorStyle ={
  color:'red',
  fontSize:'9pt',
  marginTop: 2
}

function Super() {return(<span style={{ color: 'red', verticalAlign: 'super' }}>*</span>)}
const newRecord = {
  "commodity": "Bottled Products (non-beverages)",
  "full_load_quantity": 1,
  "full_load_weight_unit": "kilograms",
  "to_type": "door",
  "from_where": "567 West Lake Street, Chicago, IL, USA",
  "additionalItems":[
    {"code": "",
    "label": "",
   "quantity":1,
   "rate":"1",
   "margin":"1",
   "marginrate":"1",
   "ratetotal":"0"
  }
    
  ],
  "totalAdditionals":0,
  "totalCargo":0,
  "totalGrand":0,
  "to_where": "718 Teaneck Road, Teaneck, NJ, USA",
  "currency": "ANG-Netherlands Antillean Guilder",
  "ship_name": "",
  "from_address": {
      "country": "US",
      "route": "W Lake St",
      "administrative_area_level_2": "Cook County",
      "administrative_area_level_1": "IL",
      "street_number": "567",
      "locality": "Chicago",
      "political": "West Loop",
      "neighborhood": "West Loop",
      "postal_code": "60661",
      "postal_code_suffix": "1405"
  },
  "load_type": "lcl",
  "from_type": "door",
  "item": {
      "quantity": 1,
      "weight": {
          "unit": "kilogram",
          "ship_type": "ocean",
          "value": ""
      },
      "packaging": "CARTON",
      "insured_value": {
          "amount": 0
      },
      "dimensions": {
          "unit": "inch",
          "length": "",
          "width": "",
          "ship_type": "ocean",
          "height": ""
      }
  },
  "full_load_weight": "",
  "ship_mobile": "",
  "full_load_commodity": "0",
  "ship_email": "",
  "to_address": {
      "country": "US",
      "route": "Teaneck Rd",
      "administrative_area_level_2": "Bergen County",
      "administrative_area_level_1": "NJ",
      "street_number": "718",
      "locality": "Teaneck",
      "political": "Teaneck",
      "postal_code": "07666",
      "postal_code_suffix": "4245"
  },
  "ship_type": "ocean",
  "inco_term": "CPT - Carriage Paid To",
  "requester_type": "seller",
  "full_load_container": "0",
  "full_load_container_type": "0",
  "full_load_rate": "0",
  "full_load_margin": "0",
  "full_load_marginrate": "0",
  "full_load_ratetotal": "0",
  "items": [
      {
          "quantity": 1,
          "rate":"1",
   "margin":"1",
   "marginrate":"1",
   "ratetotal":"0",
          "weight": {
              "unit": "kilograms",
              "value": 1
          },
          "packaging": "CARTON",
          "insured_value": {
              "amount": 0
          },
          "dimensions": {
              "unit": "inch",
              "length": 1,
              "width": 1,
              "height": 1
          }
      }
  ],
  "ship_date": dayjs("2024-08-30T18:30:00.000Z")
}
const itemRecord ={
  "quantity":"",
  "packaging":"CARTON",
"weight": {
  "value": "",
  "unit": "ounces"
},
"dimensions": {
  "unit": "inch",
  "length": "",
  "width": "",
  "height": ""
},
"insured_value":{
"amount":0
}}

const addonRecord ={"code": "",
  "label": "",
 "quantity":1,
 "rate":"1",
 "margin":"1",
 "marginrate":"1",
 "ratetotal":"0"
}

const itemRecordDefault ={
  "quantity":"1",
  "packaging":"CARTON",
"weight": {
  "value": "1",
  "unit": "ounces"
},
"dimensions": {
  "unit": "inch",
  "length": "1",
  "width": "1",
  "height": "1"
},
"insured_value":{
"amount":0
}}

interface OptionType {
  username: string;
  firstname: string;
  mobile: string;
  userid: number;
}
function QuoteEdit() {
  const navigate = useNavigate();
  const [shipRates, setShipRates] = useState<{ [key: string]: any }>({});
  const [shipErrors, setShipError] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [formType, setFormType] = React.useState('CREATE');
  const [quoteVersion, setquoteVersion] = React.useState(0);
  const [isError, setError] = React.useState(false);
  const [value, setValue] = useState<Dayjs | null>(dayjs()); //React.useState<Dayjs | null>(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const countries: CountryType[] = DEFAULTS.COUNTRIES.sort((a: any, b: any) => a.label.localeCompare(b.label));
  const [loadedValues, setLoadedValues] = useState<{ [key: string]: any }>({});
  //const [loadedValues, setLoadedValues] = useState(null);
  const [alignment, setAlignment] = React.useState('air');
  const [load, setLoad] = React.useState('ftl');
  const [customer, setCustomer] = React.useState('');
  const [finderShow, setFinderShow] = React.useState(false);
  const [modalData, setModalData] = React.useState<Record<string, any>>({});
  const [quote, setQuote] = useState<{[key: string]: any}>({});
  const { id } = useParams();
  console.log("id---", id)

  useEffect(() => {
    console.log("id---", id);
    
    const getQuote = async () => {
      try {
        const response = await axios.get(`${DEFAULTS.API_URL}quote/${id}`);
        console.log("API response:", response);

        if (response.data) {
          setQuote(response.data);
          setquoteVersion(response.data.quoteVersion)
          setLoadedValues(response.data.quoteDetails);
        }
      } catch (error) {
        console.error("Error fetching quote:", error);
      }
    };

    if (id) {
      getQuote();
      setFormType('EDIT')
    } else {
      setLoadedValues(newRecord);
      setFormType('CREATE')
      console.log("+++++++ Loaded new record:", newRecord);
    }

    // Cleanup to reset state if component unmounts
    return () => {
      setLoadedValues({}); // Reset to empty object instead of null for consistency
    };
  }, [id]);

  useEffect(() => {
    if (Object.keys(loadedValues).length > 0) { // Check if loadedValues is populated
      console.log("quote details-------", loadedValues);
    }
  }, [loadedValues]);




  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const handleDateChange = (newDate: Dayjs | null) => {
    setValue(newDate);  // Ensure newDate is a Dayjs object
  };

  useEffect(() => {
    // Fetch options only if input length is 3 or more
    if (inputValue.length >= 3) {
      setLoading(true);

      // Simulate a delayed API call with debounce (using setTimeout)
      const fetchData = setTimeout(() => {
        axios.get(DEFAULTS.API_URL+`lookup?q=${inputValue}`)
          .then((response) => {
            setOptions(response.data); // Assuming the API returns an array of options
            setLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            setLoading(false);
          });
      }, 500); // Debounce time of 500ms

      return () => clearTimeout(fetchData);
    } else {
      setOptions([]); // Clear options if input is less than 3 characters
    }
  }, [inputValue]);

  const handleAdditionalsBlur = (index: any, values: any, setFieldValue: any) => {
    const rate = Number(values.additionalItems[index].rate);
    const margin = Number(values.additionalItems[index].margin);
    const quantity = Number(values.additionalItems[index].quantity);
  
    const newItemValue = rate + (rate * (margin / 100));
    const final = newItemValue * quantity;
  
    // Update the fields with the calculated values
    setFieldValue(`additionalItems[${index}].marginrate`, newItemValue);
    setFieldValue(`additionalItems[${index}].ratetotal`, final);
    const totalQuantity = values.additionalItems.reduce((sum: any, item: any) => sum + Number(item.ratetotal), 0);
    setFieldValue('totalAdditionals', totalQuantity);
    setFieldValue('totalGrand', Number(values.totalAdditionals) + Number(values.totalCargo));
  };
  
  const handleCargoBlur = (index: any, values: any, setFieldValue: any) => {
    const rate = Number(values.items[index].rate);
    const margin = Number(values.items[index].margin);
    const quantity = Number(values.items[index].quantity);
  
    const newItemValue = rate + (rate * (margin / 100));
    const final = newItemValue * quantity;
  
    // Update the fields with the calculated values
    setFieldValue(`items[${index}].marginrate`, newItemValue);
    setFieldValue(`items[${index}].ratetotal`, final);
    const totalQuantity = values.items.reduce((sum: any, item: any) => sum + Number(item.ratetotal), 0);
    setFieldValue('totalCargo', totalQuantity);
    setFieldValue('totalGrand', Number(values.totalAdditionals) + Number(values.totalCargo));
  };
  
  
  

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };
  const handleChangeLoad = (
    event: React.MouseEvent<HTMLElement>,
    newLoad: string,
  ) => {
    setLoad(newLoad);
  };


  const postQuote = async (quote: any) => {
    setOpen(true);
    
    try {
      const response = await axios.post(DEFAULTS.API_URL + 'quote-create', quote);
      
      if (response.status === 500) {
        console.error('Internal server error occurred');
        setError(true)
      } else {
console.log("response", response.data)
setOpen(false);
if (response.data.status==="success") {
  console.log("seuccess");
  navigate('/portal/quote/complete')
} 
      }
    }
    catch (error) {
      console.error('Error occurred:', error);
      setError(true)
    }
    
    setOpen(false);
  }
  

  const saveQuote = async (quote: any) => {

    console.log("versionId", quoteVersion)
    setOpen(true);
    
    try {
      const response = await axios.post(DEFAULTS.API_URL + 'quote-save', quote,
        {
          headers: {
            quoteId: id,     // Replace with actual quoteId
            versionId: quoteVersion  // Replace with actual versionId
          }
        }
      );
      
      if (response.status === 500) {
        console.error('Internal server error occurred');
        setError(true)
      } else {
console.log("response", response.data)
setOpen(false);
if (response.data.status==="success") {
  console.log("seuccess");
  navigate('/portal/quote/complete')
} 
      }
    }
    catch (error) {
      console.error('Error occurred:', error);
      setError(true)
    }
    
    setOpen(false);
  }


  const handleFinderOpen = () => {
    setFinderShow(true);
  };

  const handleFinderClose = (dataFromModal: any, dataLabel: any, dataAddType: any) => {
    setFinderShow(false);
    console.log("modaldata", dataFromModal, dataLabel,dataAddType)
  };




  return (
    <>

                  <Heading icon={<DescriptionIcon color="secondary" />} text={`${formType} Quote # : ${pre(quote?.quoteDetails?.ship_type) + quote?.quoteId}`} />

        <Card variant="outlined" style={{ marginBottom: 10 }}>
          <CardContent>

            {loadedValues && <Formik
              initialValues= {loadedValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={async (values: any,{ setSubmitting, setErrors, setStatus }) => {

                console.log("values", values, )
                setSubmitting(false);
                if (formType === 'CREATE') {
                  await postQuote(values)
                }
                else{
                  await saveQuote(values)
                }
                
              }
              }
              render={({ values, isSubmitting, errors, touched , handleChange, handleBlur, isValid, setFieldValue }) => (
                <Form>
          {finderShow && (<Finder onCloseModal={(dataFromModal: any, dataLabel: any, dataAddType: any, dataShip: any) => {
    setFinderShow(false);
    console.log("modaldata", dataFromModal, dataLabel, dataAddType, dataShip)
    if (dataShip === "from_where") {
    setFieldValue('from_where', dataLabel)
    setFieldValue('from_address', dataFromModal)
    setFieldValue('from_type', dataAddType)
    }
    else{
      setFieldValue('to_where', dataLabel)
    setFieldValue('to_address', dataFromModal)
    setFieldValue('to_type', dataAddType)
    }
  }} data={modalData} />)} 


<Grid container spacing={4}>  
                  <Grid item xs={12} sm={12} md={4}>

<InputLabel id="from_whereLabel">Customer/Company Name<Super/></InputLabel>
{(formType === 'EDIT')?<TextField
  name="ship_name"
  fullWidth
  id="ship_name"
  size="small"
  onChange={handleChange}
  
  value={values.ship_name}
  error={touched.ship_name && Boolean(errors.ship_name)}
  InputProps={{
    endAdornment: touched.ship_name && errors.ship_name && typeof errors.ship_name === 'string' && (
      <InputAdornment position="end">
        <ErrorOutlineIcon color="error" />
      </InputAdornment>
    ),
    readOnly:true
  }}
/>:<Autocomplete
      freeSolo
      options={options}
      loading={loading}
      fullWidth
      id="ship_name"
      size="small"
      value={values.ship_name}
      onSelect={()=>{console.log("hello",inputValue)}}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          console.log("newValue is a string:", newValue); // Handle freeSolo input
        } else if (newValue && typeof newValue === 'object') {
          const selectedOption = newValue as OptionType;
          //console.log("newValue is an object, firstname:", selectedOption.firstname); // Access firstname
          setFieldValue('ship_name', selectedOption.firstname);
          setFieldValue('ship_email', selectedOption.username);
          setFieldValue('ship_mobile', selectedOption.mobile);
          setFieldValue('ship_id', selectedOption.userid);
          console.log("newValue is an object, firstname:", values);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          value={values.ship_name}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
    }

{touched.ship_name && errors.ship_name && typeof errors.ship_name === 'string' && (
  <div style={errorStyle}>{errors.ship_name}</div>)}
              
</Grid>
<Grid item xs={12} sm={12} md={4}>

<InputLabel id="from_whereLabel">Customer Email<Super/></InputLabel>
<TextField
  name="ship_email"
  fullWidth
  id="ship_email"
  size="small"
  onChange={handleChange}
  value={values.ship_email}
  error={touched.ship_email && Boolean(errors.ship_email)}
  InputProps={{
    endAdornment: touched.ship_email && errors.ship_email && typeof errors.ship_email === 'string' && (
      <InputAdornment position="end">
        <ErrorOutlineIcon color="error" />
      </InputAdornment>
    ),
    readOnly:true
  }}
/>
{touched.ship_email && errors.ship_email && typeof errors.ship_email === 'string' && (
  <div style={errorStyle}>{errors.ship_email}</div>)}



</Grid>
<Grid item xs={12} sm={12} md={4}>

<InputLabel id="from_whereLabel">Customer Phone<Super/></InputLabel>
<TextField
  name="ship_mobile"
  fullWidth
  id="ship_mobile"
  size="small"
  onChange={handleChange}
  value={values.ship_mobile}
  error={touched.ship_mobile && Boolean(errors.ship_mobile)}
  InputProps={{
    endAdornment: touched.ship_mobile && errors.ship_mobile && typeof errors.ship_mobile === 'string' && (
      <InputAdornment position="end">
        <ErrorOutlineIcon color="error" />
      </InputAdornment>
    ),
    readOnly:true
  }}
/>
{touched.ship_mobile && errors.ship_mobile && typeof errors.ship_mobile === 'string' && (
  <div style={errorStyle}>{errors.ship_mobile}</div>)}

</Grid>
</Grid>
<Divider style={{ marginTop: 20, marginBottom: 20 }}/>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={4}>
                    <InputLabel id="from_whereLaabel">Select the shipment type</InputLabel> 

                    <ToggleButtonGroup
            color="primary"
            exclusive
            id="ship_type"
            onChange={(event, value) => {
              setFieldValue('ship_type', value);
              setFieldValue('load_type', '');
            }} // Changed `formik.setFieldValue` to `setFieldValue`
            value={values.ship_type}
            aria-label="Platform"
            autoFocus
            sx={{
              "& .MuiToggleButton-root": {
               
                color: "black",
                "&.Mui-selected": {
                  color: "red",
                 
                },
              },
              "& .MuiToggleButton-label": {
                color: "white",
              },
            }}
          >
            <ToggleButton value="air"><FlightIcon/>Air</ToggleButton>
            <ToggleButton value="ocean"><DirectionsBoatIcon/>Ocean</ToggleButton>
            <ToggleButton value="truck"><LocalShippingIcon/>Truck</ToggleButton>
             {/* <ToggleButton value="courier"><FlightIcon/>Local Courier</ToggleButton>  */}
          </ToggleButtonGroup>
          {touched.ship_type && errors.ship_type && typeof errors.ship_type === 'string' && (
  <div style={errorStyle}>{errors.ship_type}</div>
)}



                    </Grid>
                    {((values.ship_type === "ocean") || (values.ship_type === "truck")) && (
                      
                      <Grid item xs={12} sm={12} md={4}>
                       <InputLabel id="from_whereLbabel">A full or partial load?</InputLabel> 
                        <ToggleButtonGroup
                          color="primary"
                          exclusive
                          id="load_typeToggle"
                          onChange={(event, value) => {
                            setFieldValue('load_type', value);
                            if (value ==='fcl' || value ==='ftl'){
                              values.items=[];
                              values.items.push(itemRecordDefault)
                              console.log("values---", values)
                            }
                          }} // Changed `formik.setFieldValue` to `setFieldValue`
                          value={values.load_type}
                          aria-label="Platform"
                          sx={{
                            "& .MuiToggleButton-root": {
                             
                              color: "black",
                              "&.Mui-selected": {
                                color: "red",
                               
                              },
                            },
                            "& .MuiToggleButton-label": {
                              color: "white",
                            },
                          }}
                        >
                          { (values.ship_type === "truck") && (<><ToggleButton value="ftl">Full (FTL)</ToggleButton>
                          <ToggleButton value="ltl">Partial(LTL)</ToggleButton></>)
                        }
                         { (values.ship_type === "ocean") && (<><ToggleButton value="fcl">Full (FCL)</ToggleButton>
                          <ToggleButton value="lcl">Partial (LCL)</ToggleButton></>)
              }

                        </ToggleButtonGroup>
                        {touched.load_type && errors.load_type && typeof errors.load_type === 'string' && (
  <div style={errorStyle}>{errors.load_type}</div>)}
                      </Grid>)

                    }
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} md={4}>

                        <InputLabel id="from_whereLabel">From where?<Super/></InputLabel>
                        <div  onClick={() => {
  setModalData({ headingText: "From where?", shipType: values.ship_type, shipField:"from_where" });
  if (values.ship_type !== '') {handleFinderOpen()};
}}>  <Tooltip title={(values.ship_type === '' ) && 'Select ship type first'}>
                        <TextField
                          name="from_where"
                          fullWidth
                          //disabled
                          id="from_where"
                          size="small"
                          value={values.from_where}
                          error={touched.from_where && Boolean(errors.from_where)}
                          style={{backgroundColor:'#e9ecef'}}
                          sx={{
                            color:"Red",
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "Red",
                            },
                            input: { cursor: 'pointer' }
                          }}
                          InputProps={{
                            placeholder:'Click to search',
                            startAdornment: (
                              <InputAdornment position="start">
                                {(values.ship_type === "truck") && <><LocalShippingIcon style={{ cursor: 'pointer !important' }}/></>}
                                {(values.ship_type === "air") && <FlightIcon style={{ cursor: 'pointer !important' }}/>}
                                {(values.ship_type === "ocean") && <DirectionsBoatIcon style={{ cursor: 'pointer !important' }}/>}
                              </InputAdornment>
                            ),
                            endAdornment: touched.from_where && errors.from_where && typeof errors.from_where === 'string' && (
                              <InputAdornment position="end">
                                <ErrorOutlineIcon color="error" />
                              </InputAdornment>
                            ),
                          }}
                        />
                        </Tooltip>
</div>
{touched.from_where && errors.from_where && typeof errors.from_where === 'string' && (
  <div style={errorStyle}>{errors.from_where}</div>)}
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                      <InputLabel id="to_whereLabel">To where?<Super/></InputLabel>
                      <div onClick={() => {
  setModalData({ headingText: "To where?", shipType: values.ship_type, shipField:"to_where" });
  if (values.ship_type !== '') {handleFinderOpen()};
}}><Tooltip  title={(values.ship_type === '' ) && 'Select ship type first'} style={{padding:40}}>
                        <TextField
                          name="to_where"
                          fullWidth
                          id="to_where"
                          size="small"
                          
                          //disabled
                          value={values.to_where}
                          error={touched.to_where && Boolean(errors.to_where)}
                          style={{backgroundColor:'#e9ecef'}}
                          sx={{
                            color:"Red",
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: "Red",
                            },
                            input: { cursor: 'pointer' }
                          }}
                          onClick={() => {
                            setModalData({ headingText: "To where?", shipType: values.ship_type, shipField:"to_where" });
                            if (values.ship_type !== '') {handleFinderOpen()};
                          }}
                          InputProps={{
                            placeholder:'Click to search',
                            startAdornment: (
                              <InputAdornment position="start">
                                {(values.ship_type === "truck") && <LocalShippingIcon/>}
                                {(values.ship_type === "air") && <FlightIcon/>}
                                {(values.ship_type === "ocean") && <DirectionsBoatIcon/>}
                              </InputAdornment>
                            ),
                            endAdornment: touched.to_where && errors.to_where && typeof errors.to_where === 'string' && (
                              <InputAdornment position="end">
                                <ErrorOutlineIcon color="error" />
                              </InputAdornment>
                            ),
                          }}
                        />
                        </Tooltip>
</div>
{touched.to_where && errors.to_where && typeof errors.to_where === 'string' && (
  <div style={errorStyle}>{errors.to_where}</div>)}
                      </Grid>
                  </Grid>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }}/>
                  <Grid container spacing={4}>

                    <Grid item xs={12} sm={12} md={4}>
  
  <InputLabel id="toggle-button-group-label">Customer is</InputLabel>
                      <ToggleButtonGroup
                        color="primary"
                        id="requester_typeToggle"
                        exclusive
                        onChange={(event, value) => setFieldValue('requester_type', value)} // Changed `formik.setFieldValue` to `setFieldValue`
                        value={values.requester_type}
                        aria-label="Platform"
                        sx={{
                          "& .MuiToggleButton-root": {
                           
                            color: "black",
                            "&.Mui-selected": {
                              color: "red",
                             
                            },
                          },
                          "& .MuiToggleButton-label": {
                            color: "white",
                          },
                        }}
                      >
                        <ToggleButton value="buyer">Buyer (Consignee)</ToggleButton>
                        <ToggleButton value="seller">Seller (Shipper) </ToggleButton>
                      </ToggleButtonGroup>
                      {touched.requester_type && errors.requester_type && typeof errors.requester_type === 'string' && (
  <div style={errorStyle}>{errors.requester_type}</div>)}
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>

                        <InputLabel id="inco_termLabel">Inco Term<Super/></InputLabel>
                        <Select
                          labelId="shipFromCountryLabel"
                          id="inco_term"
                          name="inco_term"
                          size="small"
                          label="From"
                          value={values.inco_term || '0'} 
                          onChange={handleChange}
                          fullWidth
                          endAdornment={(
                            touched.inco_term && errors.inco_term && typeof errors.inco_term === 'string' && (
                              <InputAdornment position="end">
                                <ErrorOutlineIcon color="error" />
                              </InputAdornment>
                            )
                          )}
                        ><MenuItem value="0">Select Inco Term</MenuItem>
                          {DEFAULTS.INKOTERM.map((iterms: any, idx: any) => (
                            <MenuItem key={idx} value={iterms.label}>{iterms.label}</MenuItem>
                          ))}

                        </Select>
                        {touched.inco_term && errors.inco_term && typeof errors.inco_term === 'string' && (
  <div style={errorStyle}>{errors.inco_term}</div>)}

                    </Grid>
                    <Grid item xs={12} sm={4} md={4} />


                    <Grid item xs={12} sm={4} md={4}>
                      <InputLabel id="shipFromCountryLabel">Cargo Ready On<Super/></InputLabel>



 <Field name="ship_date">
  {(props: FieldProps) => {
    const { field, form, meta } = props;
    const { error: any} = meta;

    // Check if the field has been touched
    const isFieldTouched = touched[field.name];
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={dayjs(field.value)}
          slotProps={{
            textField: {
              size: "small",
              error: false,
            },
          }}
         // onChange={(date) => form.setFieldValue('ship_date', date)}
          onError={()=>Boolean(errors)}
        />
      </LocalizationProvider>
    );
  }}
</Field>

{touched.ship_date && errors.ship_date && typeof errors.ship_date === 'string' && (
  <div style={errorStyle}>{errors.ship_date}</div>
)}  
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                        <InputLabel id="shipFromCountryLabel">Requested currency<Super/></InputLabel>
                        <Select
                          labelId="shipFromCountryLabel"
                          id="currency"
                          name="currency"
                          size="small"
                          label="From"
                          value={values.currency  || '0'}
                          onChange={handleChange}
                          fullWidth
                          endAdornment={(
                            touched.currency && errors.currency && typeof errors.currency === 'string' && (
                              <InputAdornment position="end">
                                <ErrorOutlineIcon color="error" />
                              </InputAdornment>
                            )
                          )}
                          
                        ><MenuItem value="0">Select Currency</MenuItem>
                          {DEFAULTS.CURRENCY
                          .sort((a, b) => a.is_preferred - b.is_preferred)
                          .map((currency: any, idx: any) => (
                            <MenuItem value={`${currency.code}-${currency.name}`}>{currency.code}-{currency.name}</MenuItem>
                          ))}

                        </Select>
                        {touched.currency && errors.currency && typeof errors.currency === 'string' && (
  <div style={errorStyle}>{errors.currency}</div>)}

                    </Grid>


                    <Grid item xs={12} sm={4} md={4}>
{((values.load_type ==='lcl') || (values.load_type ==='ltl') || (values.ship_type ==='air')) &&
                        <><InputLabel id="commodityLabel">Commodity<Super/></InputLabel>
                        <Select
                          labelId="shipFromCountryLabel"
                          id="commodity"
                          name="commodity"
                          size="small"
                          label="From"
                          value={values.commodity}
                          onChange={handleChange}
                          fullWidth
                          endAdornment={(
                            touched.commodity && errors.commodity && typeof errors.commodity === 'string' && (
                              <InputAdornment position="end">
                                <ErrorOutlineIcon color="error" />
                              </InputAdornment>
                            )
                          )}
                        > 
                        <MenuItem value={'0'}>Select Commodity</MenuItem>
                        {DEFAULTS.COMMODITIES.map((commodity: any, idx: any) => (
                            <MenuItem value={commodity.code}>{commodity.code} - {commodity.description}</MenuItem>
                          ))}

                        </Select>
                        {touched.commodity && errors.commodity && typeof errors.commodity === 'string' && (
  <div style={errorStyle}>{errors.commodity}</div>)}
                        </>
              }


                    </Grid>



                  </Grid>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }}/>
                  <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12}><Typography variant="h6" component="h6">Cargo</Typography></Grid>
                  </Grid>

             { (((values.ship_type ==='ocean') && (values.load_type ==='fcl')) || 
              ((values.ship_type ==='truck') && (values.load_type ==='ftl'))) &&
                  <Grid container spacing={4} style={{marginBottom:10, marginTop:10}}>
                              
                              <Grid item xs={12} md={5}>
                              <InputLabel id="weight-label">Quantity & Container<Super/></InputLabel>
                              <div>
                                  <TextField
                                    name='full_load_quantity'
                                    id="full_load_quantity"
                                    size="small"
                                    onBlur={handleBlur}
                                    value={values.full_load_quantity}
                                    onChange={handleChange}
                                    style={{width:'20%'}}
                                    InputProps={{
                                      endAdornment: touched.full_load_quantity && errors.full_load_quantity  && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                    
                                  />
                                  <Select
                          labelId="shipFromCountryLabel"
                          id="full_load_container"
                          name="full_load_container"
                          size="small"
                          label="From"
                          value={values.full_load_container}
                          onChange={handleChange}
                          style={{width:'40%'}}
                          endAdornment={(
                            touched.full_load_container && errors.full_load_container && typeof errors.full_load_container === 'string' && (
                              <InputAdornment position="end">
                                <ErrorOutlineIcon color="error" />
                              </InputAdornment>
                            )
                          )}
                        >
                           <MenuItem value={'0'}>Container Size </MenuItem>
                          {((values.ship_type ==="ocean")? DEFAULTS.TRUCK_FCL_CONTAINER : DEFAULTS.TRUCK_FCL_CONTAINER).map((container: any, idx: any) => (
                            <MenuItem key={idx} value={container.code}> {container.description}</MenuItem>
                          ))}

                        </Select>
                        {(values.ship_type ==="ocean") &&<Select
                          labelId="shipFromCountryLabel"
                          id="full_load_container_type"
                          name="full_load_container_type"
                          size="small"
                          label="From"
                          value={values.full_load_container_type}
                          onChange={handleChange}
                          style={{width:'40%'}}
                          endAdornment={(
                            touched.full_load_container_type && errors.full_load_container_type && typeof errors.full_load_container_type === 'string' && (
                              <InputAdornment position="end">
                                <ErrorOutlineIcon color="error" />
                              </InputAdornment>
                            )
                          )}
                        >
                          <MenuItem value={'0'}>Container Type</MenuItem>
                          {DEFAULTS.OCEAN_FCL_CONTAINER_TYPES.map((container: any, idx: any) => (
                            <MenuItem key={idx} value={container.code}> {container.name}</MenuItem>
                          ))}

                        </Select>}
                        </div>
                        <div style={errorStyle}>
  {touched.full_load_quantity && errors.full_load_quantity && (
    <>{errors.full_load_quantity}</>
  )}
</div>
{touched.full_load_container && errors.full_load_container && typeof errors.full_load_container === 'string' && (
  <div style={errorStyle}>{errors.full_load_container}</div>)}
                        {touched.full_load_container_type && errors.full_load_container_type && typeof errors.full_load_container_type === 'string' && (
  <div style={errorStyle}>{errors.full_load_container_type}</div>)}
                                </Grid>

                                <Grid item xs={12} sm={4} md={4}>

                        <InputLabel id="commodityLabel">Commodity<Super/></InputLabel>
                        <Select
                          labelId="shipFromCountryLabel"
                          id="commodity"
                          name="commodity"
                          size="small"
                          label="From"
                          value={values.commodity}
                          onChange={handleChange}
                          fullWidth
                          endAdornment={(
                            touched.commodity && errors.commodity && typeof errors.commodity === 'string' && (
                              <InputAdornment position="end">
                                <ErrorOutlineIcon color="error" />
                              </InputAdornment>
                            )
                          )}
                        > <MenuItem value={'0'}>Select Commodity</MenuItem>
                          {DEFAULTS.COMMODITIES.map((commodity: any, idx: any) => (
                            <MenuItem value={commodity.code}>{commodity.code} - {commodity.description}</MenuItem>
                          ))}

                        </Select>

                        {touched.commodity && errors.commodity  && typeof errors.commodity === 'string' && (
  <div style={errorStyle}>{errors.commodity}</div>)}
                    </Grid>

                    <Grid item xs={12} md={3}>
                                <InputLabel id="toggle-button-group-label">Unit weight (optional)</InputLabel>
                               <TextField
                                    name='full_load_weight'
                                    id="full_load_weight"
                                    size="small"
                                    value={values.full_load_weight}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder='Weight'
                                    style={{width:'50%',borderRight:'0px',borderTopRightRadius:'0px', borderBottomRightRadius:'0px'}}
                                    InputProps={{
                                      endAdornment: touched.full_load_weight && errors.full_load_weight  && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <Select
                      id="full_load_weight_unit"
                      labelId="weight-label"
                      name="full_load_weight_unit"
                      size="small"
                      label="Weight in"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.full_load_weight_unit}
                      style={{width:'50%',borderRight:'0px',borderTopLeftRadius:'0px', borderBottomLeftRadius:'0px'}}
                    >
                      <MenuItem value="grams">Grams</MenuItem>
                      <MenuItem value="pounds">Pounds</MenuItem>
                      <MenuItem value="kilograms">Kgs</MenuItem>
                      <MenuItem value="ounces">Ounces</MenuItem>
                    </Select>
               
                                </Grid>

                                <Grid item xs={12} md={2}>
<InputLabel id="toggle-button-group-label">Rate<Super/></InputLabel>
                                   <TextField
                                    name="full_load_rate"
                                    size="small"
                                    value={values.full_load_rate}
                                    onChange={handleChange}


                                    InputProps={{
                                      endAdornment: touched.full_load_rate && errors.full_load_rate  && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
</Grid>    

<Grid item xs={12} md={2}>
<InputLabel id="toggle-button-group-label">Margin<Super/></InputLabel>
                                   <TextField
                                    name="full_load_margin"
                                    size="small"
                                    value={values.full_load_margin}
                                    onChange={handleChange}


                                    InputProps={{
                                      endAdornment: touched.full_load_margin && errors.full_load_margin  && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
</Grid>  
<Grid item xs={12} md={2}>
<InputLabel id="toggle-button-group-label">Margin Rate<Super/></InputLabel>
                                   <TextField
                                    name="full_load_marginrate"
                                    size="small"
                                    value={values.full_load_marginrate}
                                    onChange={handleChange}

                                    InputProps={{
                                      endAdornment: touched.full_load_marginrate && errors.full_load_marginrate  && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
</Grid>   
<Grid item xs={12} md={2}>
<InputLabel id="toggle-button-group-label">Total <Super/></InputLabel>
                                   <TextField
                                    name="full_load_ratetotal"
                                    size="small"
                                    value={values.full_load_ratetotal}
                                    onChange={handleChange}

                                    InputProps={{
                                      endAdornment: touched.full_load_ratetotal && errors.full_load_ratetotal  && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
</Grid>                     
                    </Grid>
              }

              { ((values.ship_type ==='air' || values.ship_type ==='courier') || 
              ((values.ship_type ==='ocean') && (values.load_type ==='lcl')) || 
              ((values.ship_type ==='truck') && (values.load_type ==='ltl'))) &&  <FieldArray name="items">
                      {({ insert, remove, push }) => (
                        <>
                        <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                          
                          </Grid>
                          </Grid>
                          {values.items.map((item: any, index: number) => (
                            <div key={index}>
                              
                              <Grid container spacing={4} style={{marginBottom:10, marginTop:10}}>
                              
                              <Grid item xs={12} md={2}>
                              <InputLabel id="weight-label">Quantity<Super/></InputLabel>
                                  <TextField
                                    name={`items[${index}].quantity`}
                                    size="small"
                                    value={values.items[index].quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder='Quantity'
                                    InputProps={{
                                      endAdornment: getIn(touched, `items[${index}].quantity`) && getIn(errors, `items[${index}].quantity`) && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} md={4}>

                                <InputLabel id="toggle-button-group-label">Packaging<Super/></InputLabel>
                                {((values.load_type==="lcl")|| (values.ship_type==="air")) && <><ToggleButtonGroup
    color="primary"
    exclusive
    onChange={(event, value) => setFieldValue(`items[${index}].packaging`, value)}
    aria-label="Platform"
    //id={`items[${index}].packaging`}
    value={values.items[index].packaging}
    size='small'
    sx={{
        "& .MuiToggleButton-root": {
            color: "black",
            "&.Mui-selected": {
                color: "red",
            },
        },
        "& .MuiToggleButton-label": {
            color: "white",
        },
    }}
>
    <ToggleButton value="CARTON">{(Number(values.items[index].quantity) > 1)? 'Cartons' : 'Carton'}</ToggleButton>
    <ToggleButton value="CRATE">{(Number(values.items[index].quantity) > 1)? 'Crates' : 'Crate'}</ToggleButton>
    <ToggleButton value="DRUM">{(Number(values.items[index].quantity) > 1)? 'Drums' : 'Drum'}</ToggleButton>
    <ToggleButton value="PALLET">{(Number(values.items[index].quantity) > 1)? 'Pallets' : 'Pallet'}</ToggleButton>
</ToggleButtonGroup>
<Field name={`items[${index}].packaging`} style={{ display: 'none' }} id={`items[${index}].packaging`}/>
  {getIn(touched, `items[${index}].packaging`) && getIn(errors, `items[${index}].packaging`)  && (
  <div style={errorStyle}>Select a packaging</div>)}
                    </>}
                      {(values.load_type==="ltl") && <><Select
  labelId="shipFromCountryLabel"

  size="small"
  label="From"
  onChange={(event) => setFieldValue(`items[${index}].packaging`, event.target.value)}
  aria-label="Platform"
  id={`items[${index}].packaging`}
  name={`items[${index}].packaging`}
  value={values.items[index].packaging}
  fullWidth
>
  {DEFAULTS.TRUCK_LCL_PACKAGING.map((packaging: any, idx: any) => (
    <MenuItem value={packaging.code}>{packaging.description}</MenuItem>
  ))}

</Select>
{getIn(touched, `items[${index}].packaging`) && getIn(errors, `items[${index}].packaging`)  && (
  <div style={errorStyle}>Select a packaging</div>)}
</>
              }
                                  </Grid>

                                

                                  <Grid item xs={12} md={5}/>
                                  <Grid item xs={12} md={1}>
                                  <Button
                                    type="button"
                                    variant="text"
                                    size='small'
                                    onClick={() => remove(index)}
                                    disabled={values.items.length === 1}
                                    style={{width:'50%',color:'red'}}
                                    sx={{ borderRadius: '0', minWidth: 'unset', width: '30px', height: '30px' }}
                                  >
                                    <DeleteOutlineIcon />
                                  </Button>

                                  <Button
                            variant="text"
                            type="button"
                            size='small'
                            style={{width:'50%', color:'black'}}
                            sx={{ borderRadius: '0', minWidth: 'unset', width: '30px', height: '30px' }}
                            onClick={() => push(itemRecord)}
                          >
                            <AddIcon />
                          </Button>
                                </Grid>
                                </Grid>
                                <Grid container spacing={4}>
                                <Grid item xs={12} md={3}>
                                <InputLabel id="toggle-button-group-label">Unit Weight<Super/></InputLabel>
                               <TextField 
  name={`items[${index}].weight.value`}
  size="small"
  value={values.items[index].weight.value}
  onChange={handleChange}
  onBlur={handleBlur}
  placeholder="Weight"
  style={{ width: '50%', borderRight: '0px', borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }}
  InputProps={{
    endAdornment: getIn(touched, `items[${index}].weight.value`) && getIn(errors, `items[${index}].weight.value`)  && (
      <InputAdornment position="end">
        <ErrorOutlineIcon color="error" />
      </InputAdornment>
    ),
  }}
  />
                                  <Select
                      id="unitWeight"
                      labelId="weight-label"
                      name={`items[${index}].weight.unit`}
                      size="small"
                      label="Weight in"

                      value={values.items[index].weight.unit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="grams">Grams</MenuItem>
                      <MenuItem value="pounds">Pounds</MenuItem>
                      <MenuItem value="kilograms">Kgs</MenuItem>
                      <MenuItem value="ounces">Ounces</MenuItem>
                    </Select>
               
                                </Grid>


                                <Grid item xs={12} md={5}>
                                <InputLabel id="toggle-button-group-label">Unit Dimensions<Super/></InputLabel>
                                  <TextField
                                    name={`items[${index}].dimensions.length`}
                                    size="small"
                                    value={values.items[index].dimensions.length}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Length"
                                    style={{width:'25%'}}
                                    InputProps={{
                                      endAdornment: getIn(touched, `items[${index}].dimensions.length`) && getIn(errors, `items[${index}].dimensions.length`)  && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />

                                  <TextField
                                    name={`items[${index}].dimensions.width`}
                                    size="small"
                                    value={values.items[index].dimensions.width}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Width"
                                    style={{width:'25%'}}
                                    InputProps={{
                                      endAdornment: getIn(touched, `items[${index}].dimensions.width`) && getIn(errors, `items[${index}].dimensions.width`) && typeof getIn(errors, `items[${index}].dimensions.width`) === 'string' && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />

                                  <TextField
                                    name={`items[${index}].dimensions.height`}
                                    size="small"
                                    value={values.items[index].dimensions.height}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Height"
                                    style={{width:'25%'}}
                                    InputProps={{
                                      endAdornment: getIn(touched, `items[${index}].dimensions.height`) && getIn(errors, `items[${index}].dimensions.height`) && typeof getIn(errors, `items[${index}].dimensions.height`) === 'string' && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />


                    <Select
                      labelId="dimension-label"
                      id="unitDimension"
                      name={`items[${index}].dimensions.unit`}
                      size="small"

                      value={values.items[index].dimensions.unit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{width:'25%'}}
                    >
                      <MenuItem value="inch">Inch</MenuItem>
                      <MenuItem value="centimeter">Cm</MenuItem>

                    </Select></Grid>
                    <Grid item xs={12} md={1}>
                    <InputLabel id="toggle-button-group-label">Price<Super/></InputLabel>
                    <TextField
                                    name={`items[${index}].rate`}
                                    size="small"
                                    value={values.items[index].rate}
                                    onChange={handleChange}
                                    onBlur={() => handleCargoBlur(index, values, setFieldValue)}
                                    placeholder="Height"
                                    InputProps={{
                                      endAdornment: getIn(touched, `items[${index}].rate`) && getIn(errors, `items[${index}].rate`) && typeof getIn(errors, `items[${index}].rate`) === 'string' && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  </Grid>
                                                 <Grid item xs={12} md={1}>
                                                 <InputLabel id="toggle-button-group-label">Margin<Super/></InputLabel>
                                                                    <TextField
                                    name={`items[${index}].margin`}
                                    size="small"
                                    value={values.items[index].margin}
                                    onChange={handleChange}
                                    onBlur={() => handleCargoBlur(index, values, setFieldValue)}
                                    placeholder="Height"

                                    InputProps={{
                                      endAdornment: getIn(touched, `items[${index}].margin`) && getIn(errors, `items[${index}].margin`) && typeof getIn(errors, `items[${index}].margin`) === 'string' && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  /></Grid>
                                                 <Grid item xs={12} md={1}>
                                                 <InputLabel id="toggle-button-group-label">Final<Super/></InputLabel>
                                                                    <TextField
                                    name={`items[${index}].marginrate`}
                                    size="small"
                                    value={values.items[index].marginrate}
                                    onChange={handleChange}
                                    onBlur={() => handleCargoBlur(index, values, setFieldValue)}
                                    placeholder="Height"

                                    InputProps={{
                                      endAdornment: getIn(touched, `items[${index}].marginrate`) && getIn(errors, `items[${index}].marginrate`) && typeof getIn(errors, `items[${index}].marginrate`) === 'string' && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  /></Grid>
                                                 <Grid item xs={12} md={1}>
                                                 <InputLabel id="toggle-button-group-label">Total<Super/></InputLabel>
                                   <TextField
                                    name={`items[${index}].ratetotal`}
                                    size="small"
                                    value={values.items[index].ratetotal}
                                    onChange={handleChange}
                                    onBlur={() => handleCargoBlur(index, values, setFieldValue)}
                                    placeholder="Height"

                                    InputProps={{
                                      endAdornment: getIn(touched, `items[${index}].ratetotal`) && getIn(errors, `items[${index}].ratetotal`) && typeof getIn(errors, `items[${index}].ratetotal`) === 'string' && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                            </Grid>




                                
                              </Grid>
                            </div>
                          ))}
                        </>
                      )}
                    </FieldArray>
                     }

                  <Divider style={{ marginTop: 20, marginBottom: 20 }}/>
                  
                  <Grid container spacing={1}>

                  <Grid item xs={12} sm={12} md={12}><Typography variant="h6" component="h6">Additonals</Typography></Grid>
               <FieldArray name="additionalItems">
                
               {({ insert, remove, push }) => (
                        <>
 
                          {values.additionalItems && values.additionalItems.map((item: any, index: number) => (
                            <div key={index}>


<Grid container spacing={2}>
<Grid item xs={12} md={3}>
<InputLabel id="toggle-button-group-label">Additional Item<Super/></InputLabel>
<Select
  labelId="shipFromCountryLabel"

  size="small"
  label="From"
  onChange={(event) => setFieldValue(`additionalItems[${index}].code`, event.target.value)}
  aria-label="Platform"
  id={`additionalItems[${index}].code`}
  name={`additionalItems[${index}].code`}
  value={values.additionalItems[index].code}
  fullWidth
>
  {DEFAULTS.ADDITIONALS.map((addon: any, idx: any) => (
    <MenuItem value={addon.code}>{addon.label}</MenuItem>
  ))}

</Select>

</Grid><Grid item xs={12} md={1}>
<InputLabel id="toggle-button-group-label">Quantity<Super/></InputLabel>
                                   <TextField
                                    name={`additionalItems[${index}].quantity`}
                                    size="small"
                                    value={values.additionalItems[index].quantity}
                                    onChange={handleChange}
                                    onBlur={() => handleAdditionalsBlur(index, values, setFieldValue)}
                                    placeholder="Height"

                                    InputProps={{
                                      endAdornment: getIn(touched, `additionalItems[${index}].quantity`) && getIn(errors, `additionalItems[${index}].quantity`) && typeof getIn(errors, `additionalItems[${index}].quantity`) === 'string' && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
</Grid><Grid item xs={12} md={1}>
<InputLabel id="toggle-button-group-label">Rate<Super/></InputLabel>
                                   <TextField
                                    name={`additionalItems[${index}].rate`}
                                    size="small"
                                    value={values.additionalItems[index].rate}
                                    onChange={handleChange}
                                    placeholder="Height"
                                    onBlur={() => handleAdditionalsBlur(index, values, setFieldValue)}
                                    InputProps={{
                                      endAdornment: getIn(touched, `additionalItems[${index}].rate`) && getIn(errors, `additionalItems[${index}].rate`) && typeof getIn(errors, `additionalItems[${index}].rate`) === 'string' && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
</Grid><Grid item xs={12} md={1}>
<InputLabel id="toggle-button-group-label">Margin<Super/></InputLabel>
                                   <TextField
                                    name={`additionalItems[${index}].margin`}
                                    size="small"
                                    value={values.additionalItems[index].margin}
                                    onChange={handleChange}
                                    onBlur={() => handleAdditionalsBlur(index, values, setFieldValue)}
                                    placeholder="Height"

                                    InputProps={{
                                      endAdornment: getIn(touched, `additionalItems[${index}].margin`) && getIn(errors, `additionalItems[${index}].margin`) && typeof getIn(errors, `additionalItems[${index}].margin`) === 'string' && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />


</Grid><Grid item xs={12} md={1}>
<InputLabel id="toggle-button-group-label">Final<Super/></InputLabel>
                                   <TextField
                                    name={`additionalItems[${index}].marginrate`}
                                    size="small"
                                    value={values.additionalItems[index].marginrate}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Height"
                                    
                                    InputProps={{
                                      endAdornment: getIn(touched, `additionalItems[${index}].marginrate`) && getIn(errors, `additionalItems[${index}].marginrate`) && typeof getIn(errors, `additionalItems[${index}].marginrate`) === 'string' && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                      readOnly:true
                                    }}
                                  />

                                  </Grid><Grid item xs={12} md={1}>
<InputLabel id="toggle-button-group-label">Total<Super/></InputLabel>
                                   <TextField
                                    name={`additionalItems[${index}].ratetotal`}
                                    size="small"
                                    value={values.additionalItems[index].ratetotal}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Height"

                                    InputProps={{
                                      endAdornment: getIn(touched, `additionalItems[${index}].ratetotal`) && getIn(errors, `additionalItems[${index}].ratetotal`) && typeof getIn(errors, `additionalItems[${index}].ratetotal`) === 'string' && (
                                        <InputAdornment position="end">
                                          <ErrorOutlineIcon color="error" />
                                        </InputAdornment>
                                      ),
                                      readOnly:true
                                    }}
                                  />
</Grid><Grid item xs={12} md={2}>
<Button
                                    type="button"
                                    variant="text"
                                    size='small'
                                    onClick={() => remove(index)}
                                    disabled={values.additionalItems.length === 1}
                                    style={{width:'50%',color:'red'}}
                                    sx={{ borderRadius: '0', minWidth: 'unset', width: '30px', height: '30px' }}
                                  >
                                    <DeleteOutlineIcon />
                                  </Button>

                                  <Button
                            variant="text"
                            type="button"
                            size='small'
                            style={{width:'50%', color:'black'}}
                            sx={{ borderRadius: '0', minWidth: 'unset', width: '30px', height: '30px' }}
                            onClick={() => push(addonRecord)}
                          >
                            <AddIcon />
                          </Button>
                          </Grid></Grid>

                            </div>
                          ))}
</>
                          )}
                    </FieldArray> 

                  </Grid>
                  <Divider style={{ marginTop: 20, marginBottom: 20 }}/>

                  <Grid container spacing={2}>
<Grid item xs={12} md={9}></Grid>
<Grid item xs={12} md={3}> <p>Package Total : {values.totalCargo}</p>
<p>Additional Total:{values.totalAdditionals}</p>
<p> Grand Total :{values.totalGrand}</p>
               </Grid></Grid>
               <Divider style={{ marginTop: 20, marginBottom: 20 }}/>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    style={{ backgroundColor: '#eb4034', marginTop: 20, marginBottom: 20 }}
                    disabled={loading}
                  >
                    {(formType === 'CREATE')? 'CREATE QUOTE' : 'SAVE QUOTE'}
                  </Button>
                  {isError && 
      <Alert severity="error" style={{marginTop:10, marginBottom:10}}>
        An error has occured during the quote processing. Please try again later or contact our sales team : sales@ikeocean.com<br/>
        </Alert>
      }
                </Form>
              )} />

            }
              </CardContent>
        </Card>


        <Modal
  open={open}
  onClose={(event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  }}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
     
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      <p>Sit back while we process your quote request.</p>
      <p><LinearProgress color="inherit" /></p>
    </Typography>
  </Box>
</Modal>

      </>
  );
}



export default QuoteEdit;