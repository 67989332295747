import React, {useState, useEffect, useMemo} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid'; // Grid version 1
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import Deposits from './Deposits';

import axios from 'axios';
import { RootState, useAppDispatch } from '../../../../store'
import { useSelector } from 'react-redux'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import { DEFAULTS } from '../../../../Constants/Constants'

import DashboardPanel from './DashboardPanel'

type adminType = {
  [key: string]: any;
}
function Dashboard() {
  const token = localStorage.getItem('admin-token');
  const [dataFetched, setDataFetched] = useState(true)
  const ADMINDETAILS: adminType | null = useMemo(() => {
    const adminDetailsString = localStorage.getItem('admin-details');
    return adminDetailsString ? JSON.parse(adminDetailsString) : null;
  }, []);

  const usertype = ADMINDETAILS?.type.toLowerCase()
  console.log('token', token)
      return (
<DashboardPanel />
      )

    }

  export default Dashboard;