import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, TextField, Divider, Grid, Select, MenuItem, Alert } from '@mui/material';
import axios from 'axios';
import { DEFAULTS } from '../../../../Constants/Constants';
import { SelectChangeEvent } from '@mui/material';
import Heading from '../../Common/Header/Heading';
import GroupIcon from '@mui/icons-material/Group';
interface UserModalProps {
  open: boolean;
  onClose: () => void;
  row: any;
}

const User: React.FC<UserModalProps> = ({ open, onClose, row }) => {
  const [commission, setCommission] = useState('');
  const [status, setStatus] = useState('');
  const [error, setError] = useState(false); // State to track commission validation
  const [statusError, setStatusError] = useState(false); // State to track status selection
  const [submissionSuccess, setSubmissionSuccess] = useState(false); // State to track success response
  const [apiError, setApiError] = useState(false); // State to track API error
  const token = localStorage.getItem('admin-token');

  const modalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  // Initialize state values from the row prop
  useEffect(() => {
    setCommission(row?.commission || '');
    setStatus(row?.status || '');
  }, [row]);


  // Handle commission input change
  const handleCommissionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) { // Allow only numbers and decimals
      setCommission(value);
      setError(false); // Reset error on valid input
    } else {
      setError(true); // Set error on invalid input
    }
  };

  // Handle status dropdown change
  const handleStatusChange = (e: SelectChangeEvent<string>) => {
    setStatus(e.target.value as string);
    setStatusError(false); // Reset error on valid selection
  };

  // Handle form submission
  const handleSubmit = () => {
    // Validate inputs
    if (!commission || isNaN(Number(commission))) {
      setError(true);
      return;
    }
    if (!status) {
      setStatusError(true);
      return;
    }

    const dataToSubmit = {
      ...row,
      commission,
      status,
    };

    // Make the API request using Axios
    axios
      .put(DEFAULTS.API_URL + 'user', dataToSubmit, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('Submission successful:', response.data);
        setSubmissionSuccess(true); // Set success state to true
        setApiError(false); // Reset API error state
        setTimeout(onClose, 2000); // Close the modal after 2 seconds
      })
      .catch((error) => {
        console.error('There was an error submitting the data:', error);
        setApiError(true); // Set API error state
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        {submissionSuccess ? (
          <>
            <Typography variant="h6" component="h2">
              {status === 'APPROVED' ? 'Agent successfully approved!' : 'Agent successfully rejected!'}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button variant="contained" color="secondary" onClick={onClose}>
                Close
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h6" component="h2">
              Agent Details
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="subtitle1"><strong>ID</strong></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{row?.userid}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1"><strong>Profile Name</strong></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{row?.userProfileName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1"><strong>Address</strong></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{row?.address}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1"><strong>Country</strong></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{row?.country}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1"><strong>Mobile</strong></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{row?.mobile}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1"><strong>Email</strong></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{row?.username}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1"><strong>Commission</strong></Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={commission}
                  onChange={handleCommissionChange}
                  error={error}
                  helperText={error ? 'Please enter a valid number for commission' : ''}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography variant="subtitle1"><strong>Status</strong></Typography>
              </Grid>
              <Grid item xs={6}>
                <Select
                  value={status}
                  onChange={handleStatusChange}
                  fullWidth
                  displayEmpty
                  error={statusError}
                >
                  <MenuItem value="" disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                </Select>
                {statusError && (
                  <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                    Please select a status
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />

            {apiError && (
              <Alert severity="error" sx={{ mb: 2 }}>
                Error occurred. Please try again.
              </Alert>
            )}

            <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
              <Button variant="contained" onClick={handleSubmit} disabled={error || statusError}>
                Submit
              </Button>
              <Button variant="contained" color="secondary" onClick={onClose}>
                Close
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default User;
