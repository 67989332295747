export const getDate = (tmpStamp: any): string => {
  const dateObj = new Date(tmpStamp);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    
  };

  const dateString: string = dateObj.toLocaleDateString(undefined, dateOptions);
  console.log(dateString);
  return dateString;
};


export const getDateTime = (tmpStamp: any): string => {
  const dateObj = new Date(tmpStamp);
  const dateTimeOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };

  const dateTimeString: string = dateObj.toLocaleDateString(undefined, dateTimeOptions);
  console.log(dateTimeString);
  return dateTimeString;
};

export const sortByDate = (records: [],sortField : any) => {
const recordsSorted = records.sort((a: any, b: any) => {
  let da: any = new Date(b[sortField])
  let   db: any = new Date(a[sortField])
  return da - db;
})
return recordsSorted
}

export const convertFirestoreTimestampToDate = (timestamp: any): string => {
  const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6); // Convert Firestore Timestamp to JavaScript Date
  return date.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short", // Month in full letters (e.g., January, February)
    year: "numeric", // Full year
  });
};

export const pre = (ship_type: any) => {
  switch (ship_type) {
    case 'air':
      return "A1";
    case 'ocean':
      return "S1";
      case 'local-courier':
        return "L1";
    default:
      return "T1";
  }
};